import React from 'react'
import Nav from '../components/nav'
import Footer from '../components/footer'
import SiteMetaData from "../components/sitemeta"
import MobileNav from "../components/mobile-nav"
import Preloader from "../components/preloader"

const Layout = (props) => (
  <div>
      <Preloader 
        type={props.type || `none`} 
        color={props.color || `white`} 
        height={props.height || `50%`} 
        width={props.width || `50%`} 
      />
      <SiteMetaData 
          title={props.title}
          pathname={props.pathname}
          robots={props.robots}
          description={props.description}/>
      {(props.noHero ? <Nav noHero={props.noHero}/> : '')}
      <div>
        {props.children}
      </div>
      <Footer />
      <MobileNav />
  </div>
)

export default Layout